function benchmark(fn, name, desc) {
  return function (...args) {
    const startTime = performance.now();
    const result = fn(...args);
    const endTime = performance.now();
    console.log(name, desc, endTime - startTime);
    return result;
  };
}

function nextTick(fn) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fn());
    }, 0);
  });
}

const groupByState = function groupByState(data) {
  return (data || []).reduce((acc, row, idx) => {
    const [
      stateName,
      loanAmount,
      duration,
      score,
      numberOfPayments,
      APRmin,
      APRmax,
      paymentAmountmin,
      paymentAmountmax,
      disclosure,
      issuingOrganization,
      pricingPolicy,
      PaymentFreqency,
      ccStatus,
      loantype,
      typeloan,
      topDisclosure,
      bottomDisclosure,	
      rightDisclosure,
      amountFinanced,
      adminFees,
      latePaymentFee,
      returnedPaymentFee,
    ] = row;

    if (!acc[stateName]) {
      acc[stateName] = {};
    }
    if (!acc[stateName].items) {
      acc[stateName].items = [];
    }

    acc[stateName].items.push({
      loanAmount,
      duration,
      score,
      numberOfPayments,
      APRmin,
      APRmax,
      paymentAmountmin,
      paymentAmountmax,
      issuingOrganization,
      pricingPolicy,
      PaymentFreqency,
      ccStatus,
      typeloan,
      loantype,
      disclosure,
      topDisclosure,
      bottomDisclosure,	
      rightDisclosure,
      amountFinanced,
      adminFees,
      latePaymentFee,
      returnedPaymentFee,
    });

    return acc;
  }, {});
};

export function createApi(DATA_SOURCE = [], MAXMIN_DATA = []) {
  const indexes = {};
  
  async function withIndex(name, fn, defaultValue = []) {
    
    if (indexes[name]) {
      return indexes[name];
    }
    return nextTick(async () => {
      try {
        const data = await fn();
        indexes[name] = data;
        return data;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    });
  }

  function toNum(str) {
    return +str.replaceAll(',', '');
  }

  function groupMaxMin() {
    return (MAXMIN_DATA || []).reduce((acc, row) => {
      const [state, uplMin = '', uplMax = '', splMin = '', splMax = ''] = row;

      const info = {
        hasSpl: true, // if a state has secure loans
        upl: {
          min: toNum(uplMin),
          max: toNum(uplMax),
        },
        spl: {
          min: toNum(splMin),
          max: toNum(splMax),
        },
      };
      // sometimes the values in the source don't match
      // eg. 8000 & 8,000
      // better to query for upl and spl types in the data
      // see getStates
      if (uplMin === splMin && uplMax === splMax) {
        info.hasSpl = false;
      }

      acc[state] = info;
      return acc;
    }, {});
  }

  async function getStates() {
    return withIndex('states', () => {
      const states = groupByState(DATA_SOURCE || []);
      // add meta data
      
      Object.entries(states).forEach(([name, stateData]) => {
        const groupedMinMax = groupMaxMin();
        const meta = groupedMinMax[name] || {};
        
        const requireScore = stateData.items.some((row) =>
          // has rows with a creditscore value other than oportunupl
          row.score !== 'oportunupl' && row.score !== 'oportunspl' && row.score !== 'oportun');
        const hasSPL = stateData.items.some((row) =>
          // has rows with a creditscore value other than oportunupl?
          row.typeloan.toLowerCase() === 'spl');
        
        // don't destructure the meta object
        meta.hasSPL = hasSPL;
        meta.requireScore = requireScore;

        // deprecation for hasSpl
        const oldSpl = meta.hasSpl;
        delete meta.hasSpl;
        Object.defineProperty(meta, 'hasSpl', {
          get() {
            console.warn(
              `meta.hasSpl is deprecated. Use meta.hasSPL instead. hasSpl has a value of ${oldSpl}. hasSPL: ${meta.hasSPL} `,
            );
            return meta.hasSPL;
          },
        });

        stateData.meta = meta;
      });
      
      return states;
    });
  }

  async function getStateNames() {
    const states = await getStates();
    return Object.keys(states);
  }

  async function getState(stateName) {
    if (!stateName) {
      return {};
    }
    const states = await getStates();
    
    return states[stateName] || {};
  }

  async function getStateMetaData(stateName) {
    const stateData = await getState(stateName);
    return stateData.meta;
  }

  async function getCreditScoreOptions(stateName) {
    if (!stateName) {
      return [];
    }

    const scores = await withIndex(
      `${stateName}_creditScores`,
      async () => {
        const states = await getStates();

        const stateData = states[stateName].items;
        if (!stateData) {
          throw new Error(`Invalid State Name ${stateName}`);
        }

        const scores = stateData.map((data) => data.score);

        return [...new Set(scores)];
      },
      [],
    );

    return scores;
  }

  
  async function getLoanAmountData({ state, score , type = 'upl'  }) { // 
    
    if (!(state && score)) {
      return {};
    }
    const amounts = withIndex(
      `${state}_${score}_${type}_amounts`,
      async () => {
        const states = await getStates();
        let  scoreData = '';
        const stateData = states[state].items;
        if (!stateData) {
          throw new Error(`Invalid State Name ${state}`);
        }
        scoreData = stateData.filter((data) => data.score === score && data.typeloan.toLowerCase() === type  );

        const amountsData = scoreData.reduce((acc, data) => {
          const { duration, loanAmount } = data;
          if (!acc[loanAmount]) {
            acc[loanAmount] = {};
          }
          if (!acc[loanAmount][duration]) {
            acc[loanAmount][duration] = [];
          }

          acc[loanAmount][duration] = data;

          // acc[loanAmount][duration].push(data)
          return acc;
        }, {});

        return amountsData;
      },
      {},
    );

    return amounts;
  }

  async function getLoanLengthData({ state, score, amount, type}) {

    return withIndex(
      `${state}_${score}_${amount}_lengths`,
      async () => {
        const loanAmountData = await getLoanAmountData({ state, score, type });

        const durations = loanAmountData[amount];
        if (!durations) {
          throw new Error(`Invalid loan amount ${amount}`);
        }

        return durations;
      },
      [],
    );
  }

  async function getLoanLengths({ state, score, amount, type }) {

    if (!(state && score && amount)) {
      return [];
    }

    const lengths = await getLoanLengthData({ state, score, amount, type });
    return Object.keys(lengths);
  }

  async function getLoanAmounts(params) {
    const loadAmounts = await getLoanAmountData(params);
    return Object.keys(loadAmounts);
  }


  async function getApr({ duration, amount, ...params }) {
    try {
      const loanAmounts = await getLoanAmountData({ amount, ...params });
      const amountData = loanAmounts[amount];
      if (!amountData) {
        throw new Error(`Invalid loan amount ${amount}`);
      }

      const durData = amountData[duration];
      if (!durData) {
        return '';
      }

      const { APRmax, APRmin } = durData;
      return {
        min: APRmin,
        max: APRmax,
      };
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  async function getPayments({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      return '';
    }

    const durData = amountData[duration];
    const { paymentAmountmax, paymentAmountmin } = durData;
    return {
      min: paymentAmountmin,
      max: paymentAmountmax,
    };
  }

  async function getNoOfPayment({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      return '';
    }

    const lenData = amountData[duration];
    const { numberOfPayments } = lenData;
    return `${numberOfPayments}`;
  }

  async function getDisclosure({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { disclosure } = row;
    return disclosure;
  }

  async function getLoanDetails({
    state, score, secure, amount, type, duration,
  }) {
    if (!state) {
      throw new Error('state is required');
    }
    console.log('type getLoadDetails', type)
    const stateData = await getState(state);
    const { meta, items } = stateData;

    const creditScores = await getCreditScoreOptions(state);

    let scoreName;
    let loanType;
    let secureType;

    if (meta.hasSPL) {
      secureType = secure;
      if (!meta.requireScore) {
        scoreName = 'oportunupl';
      }
    }

    if (meta.requireScore) {
      scoreName = score;
      if (secureType !== null) {
        
        if (secure === 'yes' && meta.hasSPL && type !== 'upl') {
          loanType = 'spl';
          
          scoreName = score;
        } else {
          
          loanType = 'upl';
          
          scoreName = score;
        }
      } else {
        // hawaii has no secure loans?
      }
    } else if (secure === 'yes' && meta.hasSPL && type !== 'upl') {
      loanType = 'spl';
      
      scoreName = score;
    } else {
      loanType = 'upl';
      
      scoreName = score;
    }

    if (creditScores.length === 1) {
      scoreName = creditScores[0];
    }

    if (meta.requireScore) {
      if (!scoreName || !creditScores.includes(scoreName)) {
        throw new Error(`Credit score is required for ${state}`);
      }
    } else if (!scoreName) {
      scoreName = 'oportunupl';
    }

    if (meta.hasSPL && !secureType) {
      throw new Error(`Secure type is required for ${state}`);
    }

    const params = {
      state,
      amount: `${amount}`,
      duration,
      score: scoreName,
      type: loanType,
    };

    const amounts = await getLoanAmounts(params);
    console.log('get the amounts here ',amounts)
    if (!amount || !amounts.includes(amount)) {
      params.amount = amounts[Math.ceil(amounts.length / 2)];
    }

    const durations = await getLoanLengths(params);

    if (!duration || !durations.includes(duration)) {
      params.duration = durations[0];
    }

    const payments = await getPayments(params);
    const noOfPayments = await getNoOfPayment(params);
    const apr = await getApr(params);
    const disclosure = await getDisclosure(params);
    const topDisclosure = await getTableTopDisclosure(params);
    const bottomDisclosure = await  getTableBottomDisclosure(params);
    const rightDisclosure = await  getTableRightDisclosure(params);
    const amountFinanced = await  getTableAmountFinanced(params);
    const adminFees = await  getTableAdminFees(params);
    const latePaymentFee = await  getTableLatePaymentFee(params);
    const returnedPaymentFee = await  getTableReturnedPaymentFee(params);
    const loanDetails = {
      meta,
      type: loanType,
      score: scoreName,
      secure,
      isSecure: secure === 'yes',
      amounts,
      amount: params.amount,
      durations,
      duration: params.duration,
      payments,
      noOfPayments,
      apr,
      disclosure,
      topDisclosure,
      bottomDisclosure,	
      rightDisclosure,
      amountFinanced,
      adminFees,
      latePaymentFee,
      returnedPaymentFee,
    };

    // console.log("loanDetails", loanDetails)

    return loanDetails;
  }

  /*
    async function runtests() {
        async function run(params) {
            const details = await getLoanDetails(params)
            return details
        }

        const states = [
            'California'
        ]

        const scores = [
            'Exceptional (750-850)', 'Very good (700-749)', 'Needs work (Below 580)'
        ]

        const secure = [
            'yes', 'has_car_payments', 'no'
        ]

        const amounts = [
            '300', '5000', '12000'
        ]

        const types = [
            '', 'spl', 'upl'
        ]

        const params = []
        states.forEach(state => {
            scores.forEach(score => {
                secure.forEach(secure => {
                    types.forEach(type => {
                        params.push({
                            state, score, secure, type
                        })
                    })

                })
            })
        })

        const promise = params.reduce((promise, param) => {
            return promise.then((results) => {
                return run(param).then((details) => {
                    results.push({ details, param })
                    return results
                })
            })
        }, Promise.resolve([]))

        const results = await promise

        results.map((result) => {
            const { loanType, score, isSecure, secure, items = {}} = result.details || {}
            console.log(result.param, score, isSecure, secure, loanType, Object.keys(items || {}).join(','))
        })
    }
    */
  
  async function getTableTopDisclosure({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { topDisclosure } = row;
    return topDisclosure;
  }
  
  async function getTableBottomDisclosure({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { bottomDisclosure } = row;
    return bottomDisclosure;
  }
  
  async function getTableRightDisclosure({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { rightDisclosure } = row;
    return rightDisclosure;
  }

  async function getTableAmountFinanced({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { amountFinanced } = row;
    return amountFinanced;
  }

  async function getTableAdminFees({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { adminFees } = row;
    return adminFees;
  }

  async function getTableLatePaymentFee({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { latePaymentFee } = row;
    return latePaymentFee;
  }

  async function getTableReturnedPaymentFee({ duration, amount, ...params }) {
    const loanAmounts = await getLoanAmountData({ amount, ...params });

    const amountData = loanAmounts[amount];
    if (!amountData) {
      throw new Error(`Invalid loan amount ${amount}`);
    }

    const row = amountData[duration];
    if (!row) {
      return '';
    }

    const { returnedPaymentFee } = row;
    return returnedPaymentFee;
  }

  return {
    getStates,
    getStateMetaData,
    getStateNames,
    getLoanLengths,
    getLoanAmounts,
    getApr,
    getPayments,
    getNoOfPayment,
    getLoanDetails,
    // runtests
  };
}
